<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Kategori Soal",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Kategori Soal",
      items: [
        {
          text: "Master Soal",
          href: "/",
        },
        {
          text: "Kategori Soal",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      modalShow: false,
    };
  },
  methods: {
    save() {
      Swal.fire({
        icon: "success",
        title: "Berhasil!",
        text: "Data berhasil di simpan",
        timer: 2000,
        timerProgressBar: true,
      });
      this.modalShow = false;
    },
  },
  // mounted() {
  //   let self = this;
  //   self.cekAksesMenu();
  // },
  // methods: {
  //   cekAksesMenu() {
  //     let self = this;
  //     var config_axios = {
  //       method: "get",
  //       url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
  //       params: {
  //         role_id: self.sessionRoleId,
  //         link_name: self.namePathUrl,
  //       },
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: "Bearer " + localStorage.access_token,
  //       },
  //     };
  //     axios(config_axios)
  //       .then((response) => {
  //         let response_data = response.data;
  //         let response_data_fix = response_data.data.status_access;
  //         if (response_data.meta.code == 200) {
  //           if (response_data_fix == false) {
  //             let timerInterval;
  //             Swal.fire({
  //               icon: "warning",
  //               title: "Oppss",
  //               text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
  //               timer: 2000,
  //               timerProgressBar: true,
  //               showCancelButton: false,
  //               showConfirmButton: false,
  //               didOpen: () => {
  //                 timerInterval = setInterval(() => {
  //                   const content = Swal.getContent();
  //                   if (content) {
  //                     const b = content.querySelector("b");
  //                     if (b) {
  //                       b.textContent = Swal.getTimerLeft();
  //                     }
  //                   }
  //                 }, 100);
  //               },
  //               willClose: () => {
  //                 clearInterval(timerInterval);
  //               },
  //             }).then((result) => {
  //               /* Read more about handling dismissals below */
  //               if (result.dismiss === Swal.DismissReason.timer) {
  //                 self.$router.push({ name: "dashboard" });
  //               }
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: response_data_fix.data.message,
  //           });
  //         }
  //       })
  //       .catch((errors) => {
  //         console.log(errors);
  //       });
  //   },
  // },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-lg-2">
                <select class="form-select" id="statusTable">
                  <option value="1">ENABLE</option>
                  <option value="0">DISABLE</option>
                </select>
              </div>
              <div class="col-lg-10">
                <div class="float-end">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="modalShow = true"
                  >
                    Tambah
                  </button>
                  <b-modal
                    v-model="modalShow"
                    title="Tambah Kategori Soal"
                    hide-footer
                  >
                    <b-form-group
                      label="Nama Kategori Soal"
                      label-for="name-input"
                      invalid-feedback="Nama Kategori Soal is required"
                      :state="nameState"
                    >
                      <b-form-input
                        id="name-input"
                        :state="nameState"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <button
                      type="button"
                      class="btn btn-primary float-end"
                      @click="save()"
                    >
                      Simpan
                    </button>
                  </b-modal>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-4">
                <table>
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <div class="col-5">&nbsp;</div>
              <div class="col-3">
                <input
                  type="text"
                  class="form-control form-control-sm"
                  id="searchTable"
                  placeholder="Cari Data ..."
                />
              </div>
            </div>
            <div class="table-responsive mb-0">
              <table class="table align-middle table-nowrap text-center">
                <thead class="table-light">
                  <tr>
                    <th class="align-middle">No</th>
                    <th class="align-middle">Kategori Soal</th>
                    <th class="align-middle">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Pilihan Ganda</td>
                    <td>
                      <button type="button" class="btn btn-primary btn-sm">
                        Edit
                      </button>
                      &nbsp;
                      <button type="button" class="btn btn-danger btn-sm">
                        Disable
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Pilihan Ganda</td>
                    <td>
                      <button type="button" class="btn btn-primary btn-sm">
                        Edit
                      </button>
                      &nbsp;
                      <button type="button" class="btn btn-danger btn-sm">
                        Disable
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Pilihan Ganda</td>
                    <td>
                      <button type="button" class="btn btn-primary btn-sm">
                        Edit
                      </button>
                      &nbsp;
                      <button type="button" class="btn btn-danger btn-sm">
                        Disable
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
